import { createGlobalStyle } from "styled-components";
import { colors, cursors } from "./variables";
import { mediaQueries } from "./queries";
// introduces dvh units
import "large-small-dynamic-viewport-units-polyfill";

export const GlobalStyles = createGlobalStyle`
  :root {
    --100dvh: calc(var(--1dvh, 1vh) * 100);
        font-size: 15px;

        ${mediaQueries.wideScreen} {
          font-size: 18px;
        }
        ${mediaQueries.desktop} {
          font-size: 15px;
        }
        ${mediaQueries.medium} {
          font-size: 15px;
        }

        ${mediaQueries.small} {
          font-size: 15px;
        }
                cursor: ${cursors.cross};

  }

  body, html {
    background: ${colors.white};

  }

  body,html, #___gatsby, #gatsby-focus-wrapper, main, .scroll-container {
    -webkit-font-smoothing: antialiased; /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */  
            cursor: ${cursors.cross};

    overflow: hidden;
    height: 100%;
    -ms-overflow-style: none; 

  }

  * {
    -webkit-font-smoothing: antialiased; /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */  
    &::-webkit-scrollbar {
      display: none;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  html::-webkit-scrollbar, .scroll-container::-webkit-scrollbar {
    display: none;
    
  }

  body {
    padding: 0;
    margin: 0;
    cursor: ${cursors.cross};

  }

  * {
    font-family: 'Monument Grotesk', sans-serif;
    box-sizing: border-box;
    cursor: ${cursors.cross};
  }
  input:focus {
    outline: ${colors.purple} 4px solid
  }
  ::selection {
    background: transparent;
  }
  p::selection {
    background: ${colors.black};
    color: ${colors.white};
  }

  #___gatsby{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
        cursor: ${cursors.cross};

  }

  #gatsby-focus-wrapper{
    padding: 0;
    margin: 0;
    box-sizing: inherit;
    width: 100vw;
    overflow-x: hidden;
        cursor: ${cursors.cross};

  }
  main {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
        cursor: ${cursors.cross};

  }

  a {
    color: ${colors.black}
  }
`;
