import { mediaQueries } from "./queries";

export const colors = {
  white: "#ffffff",
  offWhite: "#fdfdfd",
  yellow: "#FEF981",
  neonGreen: "#00FF57",
  black: `#000000`,
  darkGray: "#333333",
  gray: "#666666",
  deepPurple: "#C9C8E8",
};
export const distances = {
  p0: "0.5rem",
  p1: "1rem",
  p2: "2rem",
  p3: "4rem",
  p4: "6rem",
};

export const cursors = {
  bigCircle: `url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='13.2' stroke='black' stroke-width='1'/%3E%3C/svg%3E ")
      20 20,
    pointer`,
  smallBlackDot: `url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='7.2' fill='black' stroke='black' stroke-width='1.6'/%3E%3C/svg%3E%0A")
      16 16,
    auto`,
  arrow: `url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31 1.6001L2.2 30.4001' stroke='black' stroke-width='1.6'/%3E%3Cpath d='M16.6 1.6001L31 1.6001V16.0001' stroke='black' stroke-width='1.6'/%3E%3C/svg%3E%0A")
      13 13,
    pointer`,
  arrowWhite: `url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31 1.6001L2.2 30.4001' stroke='whitesmoke' stroke-width='1.6'/%3E%3Cpath d='M16.6 1.6001L31 1.6001V16.0001' stroke='whitesmoke' stroke-width='1.6'/%3E%3C/svg%3E%0A")
      13 13,
    pointer`,
  cross: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 3L10 17' stroke='black'/%3E%3Cpath d='M17 10L3 10' stroke='black'/%3E%3C/svg%3E%0A") 10 10, pointer`,
  crossWhite: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 3L10 17' stroke='whitesmoke'/%3E%3Cpath d='M17 10L3 10' stroke='whitesmoke'/%3E%3C/svg%3E%0A") 10 10, pointer`,
  bigCross: `url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 3L10 17' stroke='black'/%3E%3Cpath d='M17 10L3 10' stroke='black'/%3E%3C/svg%3E%0A") 16 16, pointer`,
  bigCrossWhite: `url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 3L10 17' stroke='whitesmoke'/%3E%3Cpath d='M17 10L3 10' stroke='whitesmoke'/%3E%3C/svg%3E%0A") 16 16, pointer`,
};
export const navHeight = "60px";
export const footerHeight = "120px";

export const heights = {
  h1: "3.2rem",
  h2: "4rem",
};

export const widths = {
  logo: "7rem",
  max: "1920px",
};

export const borderRadius = {
  r1: "15px",
  r2: "12px",
  r3: "8px",
  r4: "4px",
};

export const strokeWidth = {
  s1: 2,
};
export const borders = {
  b1: `solid ${strokeWidth.s1}px ${colors.black}`,
};

export const boxShadows = {
  b0: "0px 0px 1px 2px #00000008",
  b1: "1px 4px 12px 3px #00000017",
  b2: "1px 5px 13px 10px #00000010",
  b3: "1px 4px 12px 3px rgba(0, 0, 0, 0.09)",
};

export const transitions = {
  t1: "all ease-in 0.2s",
  t2: "all ease-in 0.5s",
  t3: "all ease-in-out 0.6s",
};

export const fontStyles = {
  h1: `
    font-family: 'Monument Grotesk', sans-serif;
    font-weight: 600;
    font-size: 6.5rem;
    line-height: 110%;
    letter-spacing: -1px;

    ${mediaQueries.standard} {
      font-size: 6.5rem;
      line-height: 110%;
      letter-spacing: -1px;
      }
    ${mediaQueries.medium} {
      font-size: 4.5rem;
      line-height: 110%;
      letter-spacing: -1px;
    }
    ${mediaQueries.small} {
      font-size: 3rem;
      line-height: 110%;
      letter-spacing: -1px;
    }
    ${mediaQueries.wideScreen} {
      font-size: 8rem;
      letter-spacing: -2px;
      line-height: 103%;
    }`,
  h3: `
        font-family: 'Monument Grotesk', sans-serif;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 120%;
        `,
  li: `
        font-family: 'Monument Grotesk', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        line-height: 150%;`,
  body: `
        font-family: 'Monument Grotesk', sans-serif;
        font-weight: 400;
        font-size: 1rem;`,
  capitals: `
        font-family: 'Monument Grotesk', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        font-size: 0.75rem;
        letter-spacing: 0.06rem;
        text-transform: uppercase;
        opacity: 0.9;`,
};

export const zIndex = {
  lowest: 1,
  low: 2,
  medium: 3,
  high: 4,
  highest: 5,
};

export const grid = {
  base: `
    display: grid;
    grid-template-columns: 1rem [col-start] repeat(5, 1fr) 1rem [col-end];
    grid-template-rows: 1rem [col-start] repeat(5, 1fr) ${navHeight} [col-end];
      gap: 1rem  1rem;
    ${mediaQueries.medium} {
      grid-template-columns: 0rem [col-start] repeat(3, 1fr) 0rem [col-end];
    }
  `,
  inner: `
    display: grid;
    grid-template-columns: 0rem [col-start] repeat(5, 1fr) 0rem [col-end];
    grid-template-rows: 0rem [col-start] ${navHeight} repeat(5, 1fr) 0rem [col-end];
    gap: 2rem  2rem;`,
  simpleRow: `
    display: grid;
    grid-template-columns: 0rem [col-start] repeat(5, 1fr) 0rem [col-end];
    grid-template-rows: 1fr;
    gap: 2rem 2rem;
    ${mediaQueries.medium} {
      grid-template-columns: 0rem [col-start] repeat(3, 1fr) 0rem [col-end];
    }`,
  innerAutoRows: `
    display: grid;
    grid-template-columns: 0rem [col-start] repeat(5, 1fr) 0rem [col-end];
      grid-auto-flow: column;
    grid-template-rows: 0rem [col-start] ${navHeight} [col-nav] repeat(auto-fit, minmax(100px, 1fr)) ${footerHeight} [col-end];
    gap: 2rem 2rem;
    ${mediaQueries.medium} {
      grid-template-columns: 0rem [col-start] repeat(3, 1fr) 0rem [col-end];
    }`,

  innerSection: `
    display: grid;
    grid-template-columns: 0rem [col-start] repeat(5, 1fr) 0rem [col-end];
    grid-template-rows: 0rem [col-start] repeat(5, 1fr) 0rem [col-end];
    gap: 2rem 2rem;
`,
};
